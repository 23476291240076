.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }
  
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #0072b1;
    opacity: 0.6;
    animation: loaderAnimation 2s linear infinite;
  }
  
  .loader:before {
    left: -30px;
    animation-delay: -0.5s;
  }
  
  .loader:after {
    left: 30px;
    animation-delay: 0.5s;
  }
  
  @keyframes loaderAnimation {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  