@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTQ3j6zbXWjgeg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Nunito', Arial, sans-serif;
}

.login-screen {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 300px;
	height: 300px; 
	background-color: #ffff;
	border-radius: 5px;
	padding: 20px;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: 'Nunito', Arial, sans-serif;
}

.login-form-subtitle {
	/* margin-bottom: 0.5em;  */
	margin-top: 0;
	margin-top:80px;
}

.read-more-dots {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

h2 {
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: bold;
	font-family: 'Nunito', Arial, sans-serif;
}

.linkedInLogin {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 80px; 
	/* margin-top: 20px;  */
}

.linkedInLogin img {
	max-width: 100%;
	max-height: 100%;
}

.logo{
	width:200px;
	height:200px;
}