@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTQ3j6zbXWjgeg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  /* background: linear-gradient(45deg, #f4b2b8, #f1b6a6); */
  font-family: 'Nunito', Arial, sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 800px;
  /* background: linear-gradient(45deg, #f4b2b8, #f1b6a6,#f3c6ba); */
  font-weight: 700;
	/* box-shadow: 0px 0px 5px rgba(217, 69, 69, 0.2); */
  padding: 20px;
}

.thank-you-page-heading {
  margin-bottom: 10px;
  color: #000;
  font-family: 'Nunito', Arial, sans-serif;
  font-weight: bold;
}

.thumbs-up-icon {
  width: 80px;
  height: 80px;
  background-image: url("./img/thumbs-up-regular.svg");
  background-size: cover;
  background-position: center;
  animation: thumbs-up-animation 1s ease-in-out;
}

@keyframes thumbs-up-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
