.hidden {
    display: none;
  }
  .read-more-dots {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .read-more-dots:hover {
    text-decoration: none;
  }
  
  .show-more {
    color: #0072b1;
    cursor: pointer;
  }
  
  .show-less {
    color: #0072b1;
    cursor: pointer;
  }
  a.read-more-dots {
    text-decoration: none; 
    color: #0072b1;
    
  }
  p {
    text-align: justify;
  }
  
  h3{
    text-align: center;
  }

    
  h1{
    text-align: center;
  }

