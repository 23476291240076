@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTQ3j6zbXWjgeg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  body {
	background-color: #e0dbdb;
  }
  
  .profile-image {
	position: relative;
	margin: 20px auto 10px;
	display: block;
	width: 110px;
	height: 110px;
	object-fit: cover;
	border-radius: 50%;
	border: 3px solid white;
	box-shadow: 1px 1px 1px;
	margin-bottom: 10px;
	font-family: 'Nunito', Arial, sans-serif;
  }
  
  .welcome-container {
	text-align: justify;
	margin-bottom: 10px;
	margin-top: 10px;
	font-family: 'Nunito', Arial, sans-serif;
  }
  
  .welcome-below {
	text-align: center;
	font-family: 'Nunito', Arial, sans-serif;
	

  }
  
  @media screen and (max-width: 768px) {
	.profile-image {
	  width: 60px;
	  height: 60px;
	}
  
	.welcome-container {
	  font-size: 30px;
	  font-family: 'Nunito', Arial, sans-serif;
	  
	}
  }
  
  @media screen and (max-width: 480px) {
	.profile-image {
	  width: 90px;
	  height: 90px;
	}
  
	.welcome-container {
	  font-size: 20px;
	  font-family: 'Nunito', Arial, sans-serif;
	
	}
	.welcome-below {
		margin: 10px;
	  }
	
  }
  